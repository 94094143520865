import { DecimalPipe } from "@angular/common";
import {
  afterRender,
  Component,
  signal,
  WritableSignal,
  input,
} from "@angular/core";
import { RouterLink } from "@angular/router";
import { TranslatePipe } from "@ngx-translate/core";
import { DateTime, DurationObjectUnits } from "luxon";

@Component({
  selector: "app-countdown",
  imports: [TranslatePipe, DecimalPipe, RouterLink],
  templateUrl: "./countdown.component.html",
  styleUrl: "./countdown.component.scss",
})
export class CountdownComponent {
  now = DateTime.local();
  nextYear = DateTime.local().plus({ years: 1 }).startOf("year");
  realNextYear = DateTime.local().plus({ years: 1 }).startOf("year");
  diff: WritableSignal<DurationObjectUnits> = signal(
    this.nextYear
      .diff(this.now, [
        "years",
        "months",
        "days",
        "hours",
        "minutes",
        "seconds",
        "milliseconds",
      ])
      .toObject()
  );

  year = input(0, {
    alias: "year",
  });

  years = [
    this.nextYear.year - 1,
    this.nextYear.year,
    this.nextYear.year + 1,
    this.nextYear.year + 2,
    this.nextYear.year + 3,
  ];

  constructor() {
    afterRender(() => {
      setInterval(() => {
        this.now = DateTime.local();
        if (this.year() <= 0) {
          this.nextYear = DateTime.local().plus({ years: 1 }).startOf("year");
        } else {
          this.nextYear = DateTime.local()
            .set({ year: this.year() })
            .startOf("year");
        }
        this.diff.set(
          this.nextYear
            .diff(this.now, [
              "years",
              "months",
              "days",
              "hours",
              "minutes",
              "seconds",
              "milliseconds",
            ])
            .toObject()
        );
      }, 500);
    });
  }
}
