import { Component } from "@angular/core";
import { TranslatePipe } from "@ngx-translate/core";
import { FooterComponent } from "../../template/footer/footer.component";
import { MenuComponent } from "../../template/menu/menu.component";

@Component({
  selector: "app-imprint",
  imports: [TranslatePipe, FooterComponent, MenuComponent],
  templateUrl: "./imprint.component.html",
  styleUrl: "./imprint.component.scss",
})
export class ImprintComponent {}
