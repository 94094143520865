import { Component, inject, signal, WritableSignal } from "@angular/core";
import { CountdownComponent } from "../template/countdown/countdown.component";
import { DownloadComponent } from "../template/download/download.component";
import { FooterComponent } from "../template/footer/footer.component";
import { ActivatedRoute } from "@angular/router";
import { MetaService } from "../_services/meta.service";

@Component({
  selector: "app-main",
  imports: [CountdownComponent, DownloadComponent, FooterComponent],
  templateUrl: "./main.component.html",
  styleUrl: "./main.component.scss",
})
export class MainComponent {
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly meta = inject(MetaService);

  public year: WritableSignal<number> = signal(0);

  constructor() {
    this.activatedRoute.params.subscribe((params) => {
      const year = params["year"];
      if (year && !isNaN(year)) {
        this.meta.init(year);
        this.year.set(year);
      } else {
        this.meta.init();
        this.year.set(0);
      }
    });
  }
}
