<footer [class]="fixed() ? 'fixed' : ''">
  <div class="container text-center">
    <p>
      &copy; {{ currentYear }}
      <a href="https://www.kisimedia.de" target="_blank">kisimedia.de</a> |
      <a [routerLink]="currentLang === 'de' ? '/impressum' : '/imprint'">{{
        "legal.imprint" | translate
      }}</a>
      |
      <a [routerLink]="currentLang === 'de' ? '/datenschutz' : '/privacy'">{{
        "legal.privacy" | translate
      }}</a>
    </p>
  </div>
</footer>
