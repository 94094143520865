<header>
  @if(diff().milliseconds && diff().milliseconds! > 0) {
  <div class="container text-center">
    <h2>{{ "countdown.uppertext" | translate }}</h2>

    <div class="countdown">
      @if(diff() && diff().years && diff().years! > 0) {
      <div class="countdown-item">
        <span class="countdown-item-number">
          {{ diff().years }}
        </span>
        <span class="countdown-item-text">
          @if(diff().years === 1) {
          {{ "countdown.year" | translate }}
          } @else {
          {{ "countdown.years" | translate }}
          }
        </span>
      </div>
      } @if(diff() && diff().months && diff().months! > 0) {
      <div class="countdown-item">
        <span class="countdown-item-number">
          {{ diff().months }}
        </span>
        <span class="countdown-item-text">
          @if(diff().months === 1) {
          {{ "countdown.month" | translate }}
          } @else {
          {{ "countdown.months" | translate }}
          }
        </span>
      </div>
      } @if(diff() && diff().days && diff().days! > 0) {
      <div class="countdown-item">
        <span class="countdown-item-number">
          {{ diff().days }}
        </span>
        <span class="countdown-item-text">
          @if(diff().days === 1) {
          {{ "countdown.day" | translate }}
          } @else {
          {{ "countdown.days" | translate }}
          }
        </span>
      </div>
      } @if(diff() && diff().hours && diff().hours! > 0) {
      <div class="countdown-item">
        <span class="countdown-item-number">
          {{ diff().hours }}
        </span>
        <span class="countdown-item-text">
          @if(diff().hours === 1) {
          {{ "countdown.hour" | translate }}
          } @else {
          {{ "countdown.hours" | translate }}
          }
        </span>
      </div>
      } @if(diff() && diff().minutes && diff().minutes! > 0) {
      <div class="countdown-item">
        <span class="countdown-item-number">
          {{ diff().minutes }}
        </span>
        <span class="countdown-item-text">
          @if(diff().minutes === 1) {
          {{ "countdown.minute" | translate }}
          } @else {
          {{ "countdown.minutes" | translate }}
          }
        </span>
      </div>
      } @if(diff() && diff().seconds && diff().seconds! > 0) {
      <div class="countdown-item">
        <span class="countdown-item-number">
          {{ diff().seconds | number : "1.0-0" }}
        </span>
        <span class="countdown-item-text">
          @if(diff().seconds === 1) {
          {{ "countdown.second" | translate }}
          } @else {
          {{ "countdown.seconds" | translate }}
          }
        </span>
      </div>
      }
    </div>

    <h2>{{ "countdown.lowertext" | translate }} {{ nextYear.year }}</h2>
  </div>
  } @else {
  <div class="container text-center pastYear">
    <h2>
      {{ "countdown.pastYear" | translate : { year: nextYear.year } }}
    </h2>
    <p>
      <a [routerLink]="['/']">
        {{ "countdown.pastYearText" | translate : { year: realNextYear.year } }}
      </a>
    </p>
  </div>
  }
</header>
<div class="otherYears">
  <ul>
    <li>{{ "countdown.years" | translate }}:</li>
    @for(year of years; track year) {
    <li>
      <a [routerLink]="['/year', year]">{{ year }}</a>
    </li>
    }
  </ul>
</div>
