import { IMAGE_CONFIG, NgOptimizedImage } from "@angular/common";
import { Component, inject } from "@angular/core";
import { TranslatePipe, TranslateService } from "@ngx-translate/core";
import { DateTime } from "luxon";

@Component({
  selector: "app-download",
  imports: [TranslatePipe, NgOptimizedImage],
  templateUrl: "./download.component.html",
  styleUrl: "./download.component.scss",
  providers: [
    {
      provide: IMAGE_CONFIG,
      useValue: {
        placeholderResolution: 40,
      },
    },
  ],
})
export class DownloadComponent {
  translate = inject(TranslateService);
  currentLang = this.translate.currentLang;
  nextYear = DateTime.local().plus({ years: 1 }).startOf("year").year;
  params = { year: this.nextYear };
}
