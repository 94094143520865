import { Component, inject, input } from "@angular/core";
import { RouterLink } from "@angular/router";
import { TranslatePipe, TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-footer",
  imports: [TranslatePipe, RouterLink],
  templateUrl: "./footer.component.html",
  styleUrl: "./footer.component.scss",
})
export class FooterComponent {
  translate = inject(TranslateService);
  currentYear = new Date().getFullYear();
  currentLang = this.translate.currentLang;

  fixed = input(false, {
    alias: "fixed",
  });
}
