<div class="container text-center">
  <div class="row app">
    <div class="col-xl-4">
      @if(currentLang == 'de') {
      <img
        ngSrc="./de/screen.webp"
        alt="Screenshot Silvester Countdown App"
        class="image-shadow"
        [fill]="true"
        sizes="((min-width: 50em) and (max-width: 60em)) 50em,
              ((min-width: 30em) and (max-width: 50em)) 30em,
              (max-width: 30em) 20em"
      />
      } @else {
      <img
        ngSrc="./en/screen.webp"
        alt="Screenshot New Years Countdown App"
        class="image-shadow"
        [fill]="true"
        sizes="((min-width: 50em) and (max-width: 60em)) 50em,
              ((min-width: 30em) and (max-width: 50em)) 30em,
              (max-width: 30em) 20em"
      />}
    </div>
    <div class="col-md-8 text-start">
      <h1>
        {{ "download.title" | translate }}
      </h1>
      <h3>
        {{ "download.description" | translate }}
      </h3>
      <div innerHTML="{{ 'download.text' | translate : params }}"></div>
      <h3 innerHTML="{{ 'download.greetings' | translate : params }}">
        {{ "download.greetings" | translate : params }}
      </h3>
      <div class="btns">
        <a
          type="button"
          href="https://android.newyearscountdownapp.com"
          target="_blank"
        >
          <i class="bi bi-android2"></i>
          <div
            innerHTML="{{
              'download.download' | translate : { os: 'Android' }
            }}"
          ></div>
        </a>
        <a
          type="button"
          href="https://ios.newyearscountdownapp.com"
          target="_blank"
        >
          <i class="bi bi-apple"></i>
          <div
            innerHTML="{{
              'download.download' | translate : { os: 'Apple iOS' }
            }}"
          ></div>
        </a>
      </div>
    </div>
  </div>
</div>
