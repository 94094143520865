<nav class="navbar navbar-expand-lg bg-dark fixed-top">
  <div class="container">
    <a class="navbar-brand" [routerLink]="['/']">{{
      "menu.title" | translate
    }}</a
    ><button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ms-auto">
        <li class="nav-item">
          <a class="nav-link" aria-current="page" [routerLink]="['/']"
            >Countdown</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            aria-current="page"
            href="https://ios.newyearscountdownapp.com"
            target="_blank"
            >iOS App</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            aria-current="page"
            href="https://android.newyearscountdownapp.com"
            target="_blank"
            >Android</a
          >
        </li>
      </ul>
    </div>
  </div>
</nav>
