import { DOCUMENT } from "@angular/common";
import { inject, Injectable } from "@angular/core";
import { DomSanitizer, Meta, Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { DateTime } from "luxon";

@Injectable({
  providedIn: "root",
})
export class MetaService {
  private readonly translate = inject(TranslateService);
  private readonly title = inject(Title);
  private readonly meta = inject(Meta);
  private readonly document = inject(DOCUMENT);
  private readonly sanitizer = inject(DomSanitizer);

  constructor() {}

  async init(year?: number) {
    const availableLanguages = ["en", "de"];
    const browserLang = (await this.translate.getBrowserLang()) ?? "en";
    const currentLanguage = availableLanguages.includes(browserLang)
      ? browserLang
      : "en";
    this.translate.use(currentLanguage);
    this.yearInit(year, currentLanguage);
  }

  private yearInit(year?: number, currentLanguage: string = "en") {
    let nextYear = new Date().getFullYear() + 1;
    if (year && !isNaN(year)) {
      nextYear = year;
    }
    this.metaTags(nextYear, currentLanguage);
  }

  private metaTags(year?: number, currentLanguage: string = "en") {
    this.ogTitle(year);
    this.ogDescription(year);
    this.ogImage(year, currentLanguage);
    this.meta.updateTag({ property: "og:locale", content: currentLanguage });
    const html = this.document.querySelector("html");
    if (html) {
      html.setAttribute("lang", this.translate.currentLang);
    }
  }

  private ogTitle(year?: number) {
    this.translate
      .get("meta.title", {
        year,
      })
      .subscribe((meta) => {
        this.meta.updateTag({ property: "og:title", content: meta });
        this.title.setTitle(meta);
      });
  }

  private ogDescription(year?: number) {
    this.translate
      .get("meta.description", {
        year,
      })
      .subscribe((meta) => {
        this.meta.updateTag({ property: "og:description", content: meta });
      });
  }

  private ogImage(year?: number, currentLanguage: string = "en") {
    const timezone = DateTime.local().zoneName;
    let url = `https://api.newyearscountdownapp.com/api/og-image?timezone=${timezone}&language=${currentLanguage}`;
    if (year) {
      url = `${url}&year=${year}`;
    }
    this.meta.updateTag({ property: "og:image", content: url });
  }
}
