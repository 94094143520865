import { Component, inject } from "@angular/core";
import { ActivatedRoute, RouterOutlet } from "@angular/router";
import { MetaService } from "./_services/meta.service";

@Component({
  selector: "app-root",
  imports: [RouterOutlet],
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
})
export class AppComponent {
  private readonly route = inject(ActivatedRoute);
  private readonly meta = inject(MetaService);

  constructor() {
    this.route.queryParams.subscribe((params) => {
      const year = params["year"];
      if (year && !isNaN(year)) {
        this.meta.init(year);
      } else {
        this.meta.init();
      }
    });
  }
}
